import styled from "styled-components"
import media from "styled-media-query"

export const ContatoWrapper = styled.section`
  display: block;
  padding: 1.25rem;
  background: var(--black_background_contrast);
  border-radius: 0.8rem;
  width: 100%;
  margin: 0 auto;
`

export const ContatoTitle = styled.h1`
  text-align: center;
  text-transform: uppercase;
  color: var(--white);
  font-size: 2.5rem;
  font-weight: 300;
  margin: 1.25rem 0 1.25rem;

  ${media.lessThan("large")`
    font-size: 1.5rem;
  `}
`
export const ContatoText = styled.p`
  color: var(--white);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 300;
`

export const ContatoForm = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${media.lessThan("large")`
    width: 100%;
  `}
`
export const ContatoInputHidden = styled.input`
  visibility: hidden;
  width: 100%;
  border: none;
  border-bottom: 3px solid var(--black_background);
  padding: 0.5rem;
  margin: 1rem 0;
`

export const ContatoInput = styled.input`
  width: 100%;
  background: var(--black_background);
  color: var(--white);
  border: none;
  border-bottom: 3px solid var(--black_background);
  padding: 0.5rem;
  margin: 1rem 0;

  ${media.lessThan("large")`
    padding: 0.5rem;
  `}
`

export const ContatoTextArea = styled.textarea`
  width: 100%;
  border: none;
  background: var(--black_background);
  color: var(--white);
  border-bottom: 3px solid var(--black_background);
  padding: 0.5rem;
  margin: 1rem 0;
  resize: none;

  ${media.lessThan("large")`
    padding: 0;
  `}
`
export const ContatoButton = styled.button`
  color: var(--white);
  background: var(--dark_orange);
  padding: 1rem 3rem;
  text-transform: uppercase;
  border: none;
  margin: 0 auto;
  display: block;
  transition: background 0.2s;

  &:hover {
    background: var(--orange);
  }
`
