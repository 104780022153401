import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Contato from "../components/Contato"

const ContactPage = () => (
  <Layout visibleSideBar={true}>
    <SEO
      title="Contato"
      description="Página de contato, caso tenha alguma dúvida ou precise de algum suporte não deixe de nos contatar preenchendo o formulário dessa página."
    />
    <Contato />
  </Layout>
)

export default ContactPage
